import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OpenaiService } from '../../services/openai.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

    @ViewChild('scrolltarget') scrolltarget!: ElementRef;

    messagesToDisplay: any;
    loadingPage: boolean;
    userMessage: string;

    constructor(
        public openAIService: OpenaiService,
        private spinner: NgxSpinnerService
    ) {
        this.loadingPage = true;
        this.messagesToDisplay = [];
        this.userMessage = '';
    }

    async ngOnInit() {
        await this.openAIService.initialize();
        this.messagesToDisplay = await this.openAIService.getThreadMessages();
        this.loadingPage = false;
    }

    async addMsg() {
        let messageToSend = this.userMessage.trim();
        await this.scrollToBottom();

        if ( messageToSend !== '' ) {
            this.userMessage = '';
            await this.openAIService.addUserMsg(messageToSend);
            await this.scrollToBottom();
        }
    }

    convertMsgText(m:string) {
        return m.replace(/\n/g,'<br>');
    }

    scrollToBottom() {
        this.scrolltarget.nativeElement.scroll({
            top: this.scrolltarget.nativeElement.scrollHeight,
            left: 0,
            behavior: 'smooth'
        });
    }

}
