import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
})
export class LoginComponent {

    user: any;
    inputEmail: string;
    inputPassword: string;

    constructor(private router: Router) {
        this.user = { email : 'thegreatest@gmail.com' , password : 'theGoat1!' };
        this.inputEmail = '';
        this.inputPassword = '';
    }


    login() {

        console.log(this.inputEmail.trim(),this.inputPassword.trim())

        if ( (this.inputEmail.trim() === this.user.email) && (this.inputPassword.trim() === this.user.password) ) {
            this.router.navigateByUrl('home');
        }
    }
}
