import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import OpenAI from 'openai';

@Injectable({
    providedIn: 'root',
})
export class OpenaiService {
    client: any;
    userThread: any;
    userThreadRun: any;
    messages: any;
    threadRunProcessing: boolean = false;

    constructor() {
        this.client = new OpenAI({
            apiKey : environment.apiKey,
            dangerouslyAllowBrowser: true
        });

        this.userThread = null;
        this.userThreadRun = null;
        this.messages = [];
    }

    async initialize() {
        await this.client.beta.threads.create().then( async (thread:any) => {
            this.userThread = thread;

            await this.client.beta.threads.runs.create( thread.id , { assistant_id : environment.assistantId }).then( async (threadRun:any) => {
                this.userThreadRun = threadRun;
                let runStatus = this.userThreadRun.status;
                this.threadRunProcessing = true;

                while( runStatus !== 'completed' ) {
                    await new Promise( (resolve) => { setTimeout( resolve , 500 ) } );
                    await this.client.beta.threads.runs.retrieve(this.userThread.id,this.userThreadRun.id).then( async (d:any) => { runStatus = d['status']; });
                }

                await this.client.beta.threads.messages.list(this.userThread.id).then( (messages:any) => {
                    messages['data'].forEach( (m:any) => {
                        this.messages.push({
                            role : m.role,
                            content: m.content[0].text.value
                        })
                    });
                });

                this.threadRunProcessing = false;
            });
        })
    }


    async addUserMsg(msg:string) {
        this.threadRunProcessing = true;
        let msgData = { role : "user" , content : msg };
        this.messages.push(msgData);

        await this.client.beta.threads.messages.create(this.userThread.id,msgData).then( async (data:any) => {

            await this.client.beta.threads.runs.create( this.userThread.id , { assistant_id : environment.assistantId }).then( async (threadRun:any) => {

                this.userThreadRun = threadRun;
                let runStatus = this.userThreadRun.status;

                while( runStatus !== 'completed' ) {
                    await new Promise( (resolve) => { setTimeout( resolve , 500 ) } );
                    await this.client.beta.threads.runs.retrieve(this.userThread.id,this.userThreadRun.id).then( async (d:any) => { runStatus = d['status']; });
                }

                await this.client.beta.threads.messages.list(this.userThread.id).then( (messages:any) => {
                    const lastMsg = messages['data'].filter( (m:any) => {
                        return (m.run_id === this.userThreadRun.id && m.role === 'assistant');
                    }).pop();

                    if (lastMsg) {
                        this.messages.push({
                            role : lastMsg.role,
                            content: lastMsg.content[0].text.value
                        })
                    }

                    this.threadRunProcessing = false;
                });


            });
        })

    }

    async getThreadMessages() {
        return this.messages;
    }
}
