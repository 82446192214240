import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from "ngx-spinner";
import { FormsModule } from '@angular/forms';


const routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: LoginComponent,
            },
        ],
    },
];
@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgxSpinnerModule,
    FormsModule
  ]
})
export class LoginModule { }
