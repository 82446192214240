<ngx-spinner size="large" type="ball-scale-multiple">
	<h1 class="text-white">{{ "Loading..." }}</h1>
</ngx-spinner>

<div #scrolltarget class="pg-home container-fluid">

    <div class="container col-lg-9">

        <div class="row text-center" *ngIf="loadingPage">
            <div class="col-12">
                <h4> Loading... </h4>
            </div>
        </div>

        <div *ngIf="!loadingPage">
            <div class="row msg-row" [class.userMsg]=" m.role === 'user' " *ngFor="let m of messagesToDisplay">
                <div class="col-12">
                    <p [innerHtml]="convertMsgText(m.content)"></p>
                </div>
            </div>

            <div class="row text-center" [hidden]="!loadingPage && !openAIService.threadRunProcessing">
                <div class="col-12">
                    <h6> processing... </h6>
                </div>
            </div>
        </div>

    </div>

</div>

<footer class="footer fixed-bottom" [hidden]="loadingPage">
    <div class="row">
        <div class="col-12 text-center">
            <div id="input-div">
                <textarea [(ngModel)]="userMessage" placeholder="type here..."></textarea>
                <i class="bi bi-arrow-up-square-fill" (click)="addMsg()" ></i>
            </div>
        </div>
    </div>
</footer>

