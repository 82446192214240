<div class="pg-home container-fluid">

    <div class="container col-lg-4 mt-6 text-center">

        <form>
            <div class="form-group">
            <input name="iemail" type="email" class="form-control" placeholder="Enter email" [(ngModel)]="inputEmail">
            </div>
            <div class="form-group">
            <input name="ipw" type="password" class="form-control" placeholder="Enter password" [(ngModel)]="inputPassword">
            </div>
            <button type="button" class="btn btn-primary btn-lg btn-block" (click)="login()">Submit</button>
        </form>


    </div>
</div>
