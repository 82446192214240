import { Routes } from '@angular/router';
import { HomeModule } from './modules/home/home.module';
import { LoginModule } from './modules/login/login.module';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: 'login',
        loadChildren: () => LoginModule,
    },
    {
        path: 'home',
        loadChildren: () => HomeModule,
    },
    {
        path: '**',
        redirectTo: 'home',
    },
];
