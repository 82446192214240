// File generated from our OpenAPI spec by Stainless.
import { APIResource } from 'openai/resource';
import * as FineTunesAPI from 'openai/resources/fine-tunes';
import { Page } from 'openai/pagination';
export class FineTunes extends APIResource {
    /**
     * Creates a job that fine-tunes a specified model from a given dataset.
     *
     * Response includes details of the enqueued job including job status and the name
     * of the fine-tuned models once complete.
     *
     * [Learn more about fine-tuning](https://platform.openai.com/docs/guides/legacy-fine-tuning)
     */
    create(body, options) {
        return this._client.post('/fine-tunes', { body, ...options });
    }
    /**
     * Gets info about the fine-tune job.
     *
     * [Learn more about fine-tuning](https://platform.openai.com/docs/guides/legacy-fine-tuning)
     */
    retrieve(fineTuneId, options) {
        return this._client.get(`/fine-tunes/${fineTuneId}`, options);
    }
    /**
     * List your organization's fine-tuning jobs
     */
    list(options) {
        return this._client.getAPIList('/fine-tunes', FineTunesPage, options);
    }
    /**
     * Immediately cancel a fine-tune job.
     */
    cancel(fineTuneId, options) {
        return this._client.post(`/fine-tunes/${fineTuneId}/cancel`, options);
    }
    listEvents(fineTuneId, query, options) {
        return this._client.get(`/fine-tunes/${fineTuneId}/events`, {
            query,
            timeout: 86400000,
            ...options,
            stream: query?.stream ?? false,
        });
    }
}
/**
 * Note: no pagination actually occurs yet, this is for forwards-compatibility.
 */
export class FineTunesPage extends Page {
}
(function (FineTunes) {
    FineTunes.FineTunesPage = FineTunesAPI.FineTunesPage;
})(FineTunes || (FineTunes = {}));
