import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from "ngx-spinner";
import { FormsModule } from '@angular/forms';


const routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: HomeComponent,
            },
        ],
    },
];
@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgxSpinnerModule,
    FormsModule
  ]
})
export class HomeModule { }
